import React, {useState} from "react"
import styled from 'styled-components'
import { Link } from "gatsby"
// import { FaBars } from 'react-icons/fa'
import { menuData } from '../data/MenuData'
// import { Button } from "./Button"
import Logo from '../assets/images/Vein-U_white_transperant.png'
import CloseIcon from '@material-ui/icons/Close'
import MenuIcon from '@material-ui/icons/Menu';


const Header = () => {
  const [hamburgerStatus, setHamburgerStatus] = useState(false)
  return(
      <Topbar>
    <Navbar>
      <LogoImage></LogoImage>
      {/* <NavbarLink to="/">Vein-U</NavbarLink> */}
      {/* <Bars /> */}
      <NavMenu>
        {menuData.map((item, index) => (
          <NavbarLink to={item.link} key={index}> {item.title} </NavbarLink>
        ))}
      </NavMenu>
      {/* <NavBtn>
        <Button primary="true" round="true" to="#contact">Book an Appointment</Button>
      </NavBtn> */}
      <HamburgerWrapper>
        <CustomMenu onClick={()=> setHamburgerStatus(true)}></CustomMenu>
      </HamburgerWrapper>
      <BurgerNav show={hamburgerStatus}>
        <CloseWrapper>
          <CustomClose onClick={()=> setHamburgerStatus(false)}></CustomClose>
        </CloseWrapper>
        <li><a href="#home">Home</a></li>
        <li><a href="#whyus">Why Us?</a></li>
        <li><a href="#services">Services</a></li>
        <li><a href="#howitworks">How it works?</a></li>
        <li><a href="#faqs">FAQs</a></li>
        <li><a href="#signup">Sign Up</a></li>
        <li><a href="#contact">Contact Us</a></li>
      </BurgerNav>
    </Navbar>
    </Topbar>
  )
}

export default Header

const Topbar = styled.div`

`

const LogoImage = styled.div`
  background-image: url(${Logo});
  background-position: center;
  background-size: cover;
  width: 200px;
  height: 200px;
  margin: 10px;
  cursor: pointer;
  border: 0px;
  position: relative;
  display: flex;
  padding: 0 1rem;
  padding-left: 40px;
  margin-left: 10px;
  height: 100%;
`

const Navbar = styled.nav`
  background: transparent;
  height: 80px;
  display: flex;
  justify-content: space-between;
  padding: 0.5rem calc((100vw - 1300px) / 2);
  z-index: 100;
  position: relative;
`

const NavbarLink = styled(Link)`
  color: black;
  font-weight: bold;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;
`

// const Bars = styled(FaBars)`
//   display: none;
//   color: #fff;

//   @media screen and (max-width: 768px){
//     display: block;
//     position: absolute;
//     top: 0;
//     right: 0;
//     transform: translate(-100%, 75%);
//     font-size: 1.8rem;
//     cursor: pointer;
//   };
// `

const NavMenu = styled.div`
  display: flex;
  align-items: center;
  margin-left: 20rem;
  margin-top: 2rem;
  /* margin-right: -48px; */
  position: fixed;

  @media screen and (max-width:768px){
    display: none;
  }

  @media screen and (max-width:1025px){
    display: none;
  }
`

// const NavBtn = styled.div`
//   display: flex;
//   align-items: center;
//   margin-right: 24px;

//   @media screen and (max-width: 768px){
//     display: none;
//   }

//   @media screen and (max-width: 1025px){
//     display: none;
//   }
// `

const HamburgerWrapper = styled.div`
  display: none;

  @media screen and (max-width: 768px){
    display: block;
  }

  @media screen and (max-width:1025px){
    display: block;
  }
`

const CustomMenu = styled(MenuIcon)`
  color: #fff;

  @media screen and (max-width: 768px){
     display: block;
     position: fixed;
     top: 0;
     right: 0;
     transform: translate(-100%, 75%);
     font-size: 1.8rem;
     cursor: pointer;
     margin-right: 1rem;
  }

  @media screen and (max-width: 1025px){
     display: block;
     position: fixed;
     top: 0;
     right: 0;
     transform: translate(-100%, 75%);
     font-size: 1.8rem;
     cursor: pointer;
     margin-right: 2rem;
  }
  
`
const BurgerNav = styled.div`
    display: none;
    
    @media screen and (max-width: 768px){
      position: fixed;
      top: 0;
      bottom: 0;
      right: 0;
      color: white;
      background: #b5b5b5;
      width: 300px;
      z-index: 100;
      list-style: none;
      padding: 20px;
      height: 40%;
      display: flex;
      flex-direction: column;
      text-align: start;
      transform: ${props => props.show ? 'translateX(0)' : 'translateX(100%)'};
      transition: transform 0.2s;
      
      li{
        padding: 15px 0;
        
        border-bottom: 1px solid rgba (0, 0, 0, 0.2);
        a{
          font-style: bold;
          text-decoration: none;
          color: #fff;
          padding: 1rem;
          font-size: 1.3rem;
            &:hover {
            background-color: #fff;
            color: #7d7c7c;
          }
        }

        
      }
      
    }



    @media screen and (max-width: 1025px){
      position: fixed;
      top: 0;
      bottom: 0;
      right: 0;
      color: white;
      background: #b5b5b5;
      width: 300px;
      z-index: 100;
      list-style: none;
      padding: 20px;
      height: 40%;
      display: flex;
      flex-direction: column;
      text-align: start;
      transform: ${props => props.show ? 'translateX(0)' : 'translateX(100%)'};
      transition: transform 0.2s;
      
      li{
        padding: 15px 0;
        
        border-bottom: 1px solid rgba (0, 0, 0, 0.2);
        a{
          font-style: bold;
          text-decoration: none;
          color: #fff;
          padding: 1rem;
          font-size: 1.3rem;
            &:hover {
            background-color: #fff;
            color: #7d7c7c;
          }
        }

        
      }
      
    }

`
const CustomClose = styled(CloseIcon)`
  cursor: pointer;
`
const CloseWrapper = styled.div`
  display: flex;
  justify-content: flex-end;

  @media screen and (max-width: 1025px){
    margin-right: 3rem;
  }
`